.highlighted-route-popup .highlighted-image {
    border: 2px solid #000000; /* Add the border to the image itself */
    border-radius: 5px; /* Optional: rounded corners for the image */
    max-width: 100%;
    max-height: 100%;
  }

  .highlighted-route-popup .no-data-placeholder {
    text-align: center;
    padding: 20px;
    font-size: 1.2em;
  }

  /* Frame container styling */
.frame-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
  }
  
  .frame-container.border {
    border: 2px solid #000000; /* Apply border only if no image is present */
  }
  
  .frame-container.no-border {
    border: none; /* Remove border when the image is present */
  }
  
  .frame-container img {
    max-width: 100%;
    max-height: 100%;
    height: 133px;
    width: 236px;
    height: auto;
    border-radius: 5%;
  }

  /* Navigation buttons styling */
.navigate-button {
    position: absolute;
    top: 50%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding: 5px;
    border-radius: 5px;
    transform: translateY(-50%);
  }
  
  .navigate-button:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  
  .navigate-button:first-child {
    left: 0;
  }
  
  .navigate-button:last-child {
    right: 0;
  }
  
  .hover-buttons {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    gap: 10px;
  }
  
  .hover-buttons button {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .hover-buttons button:hover {
    background: rgba(0, 0, 0, 0.9);
  }
  
  .hover-buttons div {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .timeline-container {
    position: relative;
    margin-top: 10px;
    width: 100%; /* Ensure the width is correct */
    display: flex;
    justify-content: center; /* Center the timeline */
  }

  .timeline {
    display: flex;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    background: #e0e0e0;
    width: 100%; /* Ensure the timeline fills the container */
    position: relative;
    cursor: pointer; /* Change cursor to indicate clickable area */
  }
  
  .timeline-segment {
    height: 100%;
    flex-grow: 1; /* Ensure segments fill the timeline */
  }
  
  .timeline-indicator {
    position: absolute;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid red;
    transform: translateX(-50%);
  }
  
  .timeline-indicator::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid red;
    transform: translateX(-50%);
  }

  .reading-details {
    display: flex;
    justify-content: space-between; /* Adjusted to ensure spacing */
    align-items: center;
    width: 100%;
    gap: 10px; /* Add space between elements */
  }

  .detail-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
  }
  
  .detail-item .label {
    font-size: 16px;
    color: #888;
    margin-bottom: 4px; /* Reduce space between label and value */
    text-align: center;
  }
  
  .detail-item .value {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-top: 2px; /* Reduce space between label and value */
    width: 110px; /* Set a fixed width */
    text-align: center; /* Center the text */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    transition: font-size 0.2s ease; /* Add transition for font size */
  }
  
  .detail-item .value.shrink {
    font-size: 18px; /* Smaller font size when it needs to shrink */
  }

  .separator {
    width: 2px;
    height: 110px; /* Set height to 110px */
    background-color: #ccc; /* Light grey color */
  }

  .rating-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 20px; /* Space between CVAT and IRI ratings */
  }
  
  .overall-rating {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    box-sizing: border-box;
    color: #fff;
    background-color: inherit; /* Inherits the color set dynamically */
    border: 1px solid #ccc;
    border-top: 5px solid grey;
    border-bottom: 5px solid grey;
  }
  
  .overall-rating p {
    margin: 0;
    font-size: 32px; /* Increase font size */
    color: white; /* Change text color */
    font-family: 'Arial', sans-serif; /* Change font */
  }