.main-content {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  padding-top: 70px;
}

.sidepanelContainer {
  display: flex;
  flex-direction: column;
  height: 92.25vh; 
  width: 20%; 
  background-color: #f0f0f0; 
  z-index: 1;
}

.plot-route-button {
  /* margin-top: 10px; */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff; /* Bootstrap primary color or a shade of blue */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.plot-route-button:hover {
  background-color: #0056b3; /* Darker shade of blue for hover */
}

.plot-route-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Add a subtle focus ring */
}

@media (max-width: 768px) {
  .filterContainer,
  .routeContainer {
    width: 100%;
    height: auto;
  }

  .mapContainer {
    height: 80%;
  }
}

.filterContainer, .routeContainer {
  box-sizing: border-box;
  overflow-y: auto; /* Optional: Scroll if content overflows */
}

.mapContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.routeContainer {
  padding: 20px;
  background-color: #b5b5b5;
  flex: 1; /* Ensures both containers take up equal space */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
