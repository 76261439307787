/* EditPopup.css */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  text-align: left;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  max-height: 90vh; /* Popup content does not exceed the viewport height */
  overflow-y: auto; /* Enable vertical scrolling if the content overflows */
  box-sizing: border-box; /* Ensure padding is accounted for in width/height */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #444;
}

.popup-content h3 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-size: 1.5rem;
}

.popup-content input {
  width: calc(33.3% - 10px);
  padding: 10px;
  margin: 10px 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.popup-content .update-rating-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.popup-content .update-rating-button:hover {
  background-color: #45a049;
}

.popup-content .input-label {
  display: block;
  margin: 10px 0 5px;
  color: #444;
  font-weight: bold;
}
