body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-routing-container {
  display: none;
}

.overlay {
  position: fixed;
  top: 10%; /* Adjust the top position to move the overlay down */
  left: 50%; /* Center the overlay horizontally */
  transform: translateX(-50%); /* Center the overlay horizontally */
  width: 30%; /* Adjust the width of the overlay */
  max-width: 400px; /* Set a maximum width for the overlay */
  background-color: rgba(255, 255, 255, 0.8); /* White background with 80% opacity */
  padding: 20px; /* Add padding for content inside the overlay */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  z-index: 1000; /* Ensure overlay is above the map */
}

.overlay img {
  width: 100%; /* Make the image fill the width of the overlay */
  height: auto; /* Maintain aspect ratio */
}

