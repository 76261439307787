.admin-controls {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .admin-controls button, .admin-controls select {
    margin-top: 5px;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .admin-controls button:hover {
    background-color: #0056b3;
  }
  
  .admin-controls .delete-button {
    background-color: #dc3545;
  }
  
  .admin-controls .delete-button:hover {
    background-color: #c82333;
  }

  textarea.comments-box {
    width: 100%;
    height: 80px;
    margin-top: 5px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none; /* Disables resizing */
    background-color: #f9f9f9;
  }
  
  textarea.comments-box:focus {
    outline: none;
    border-color: #007BFF;
  }
  
  textarea {
    width: 100%;
    margin-top: 5px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }