.user-dropdown {
    position: relative;
  }

.user-placeholder {
    display: flex;
    align-items: center;
    background-color: #444;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    z-index: 1050;
  }
  
.user-logo {
    height: 54px;
    margin-right: 8px;
    border-radius: 50%;
  }

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #333; /* Background similar to your design */
    border: 1px solid #444; /* Border with slightly lighter color */
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1050;
    width: 200px; /* Fixed width for the dropdown */
    padding: 10px 0; /* Padding inside the dropdown */
  }

.dropdown-item {
    padding: 10px 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
.dropdown-item span {
    color: white;
    font-weight: bold;
  }

.dropdown-username {
    font-size: 14px;
    color: #ccc; /* Slightly lighter color for the username */
  }

.dropdown-separator {
    border-top: 1px solid #444; /* Adjust the color as needed */
    margin: 10px 0;
  }

.logout-button {
    background-color: #d42e2e;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
.logout-button:hover {
    background-color: #ba1717;
  }

.login-form {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

.login-form label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
.login-form input {
    padding: 8px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }

.login-error {
    color: red;
    margin-bottom: 10px;
  }

.form-group {
    margin-bottom: 10px;
  }

.login-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
.login-button:hover {
    background-color: #45a049;
  }