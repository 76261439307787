/* Header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #444;
    color: white;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; /* Ensure header is above other elements */
  }
  
  .pave-title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-left: 10px;
  }
  
  .header-logo {
    height: 50px;
    margin-right: 10px;
    background: white;
    border-radius: 50%;
  }
  
  .right-section {
    display: flex;
    align-items: center;
    background-color: #008080; /* Teal background */
    padding: 10px;
    width: 20%;
    overflow: hidden; /* Hide overflowing content */
  }  

  .topbar-buttons {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .upload-stats-box {
    position: absolute;
    bottom: 20px; /* or bottom: 20px; */
    right: 20px;
    background-color: #ffffff;
    padding: 12px 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    color: #333;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* make sure it's above other elements */
  }
  
  

  .manageButton, .uploadButton {
    background-color: #025b01;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .manageButton:hover, .uploadButton:hover {
    background-color: #45a049;
  }

  /* Hide the title below 1185px width */
  @media screen and (max-width: 1185px) {
    .pave-title {
        display: none;
    }
    .right-section {
        justify-content: center; /* Center the logo when the title is hidden */
    }
}