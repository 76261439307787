.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    text-align: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .manage-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between each video item */
    max-height: calc(90vh - 100px); /* Adjust height to fit within popup content while accounting for padding */
    overflow-y: auto; /* Enable scrolling for the list of items */
  }

  .manage-item {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%; /* Ensure it spans the width of the container */
  }
  
  .manage-item img {
    width: 150px;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }

  .manage-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 20px;
  }
  
  .details-text {
    flex-grow: 1;
  }
  
  .manage-details p {
    margin: 5px 0; 
  }

  .details-buttons {
    display: flex;
    gap: 10px; /* Space between buttons */
  }
  
  .details-buttons button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .details-buttons button:hover {
    background-color: #e0e0e0;
  }