/* Centered and semi-transparent background */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* White box with shadow for the main content */
.popup-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
}

/* Hide the default file input */
.fileInput {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

/* Custom button for file upload */
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
  background-color: #0056b3;
}

/* Center the progress bar */
.progress-bar-container {
  margin-top: 20px;
  width: 80%;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto; /* Centers the progress bar */
}

/* Progress bar styling */
.progress-bar {
  height: 20px;
  background-color: #4caf50;
  width: 0;
  transition: width 0.3s ease-in-out;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 10px;
}

/* Loading message styling */
.upload-container p {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

/* Vehicle dropdown label styling */
.vehicle-dropdown-label {
  margin-top: 15px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  display: block;
  text-align: left;
}

/* Vehicle dropdown styling */
.vehicle-dropdown {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

/* Hover and focus states for the dropdown */
.vehicle-dropdown:hover {
  border-color: #007bff;
}

.vehicle-dropdown:focus {
  outline: none;
  border-color: #007bff;
}


/* Styling for the GoPro checkbox */
.gopro-checkbox {
  margin-top: 15px;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.gopro-checkbox input {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Style for when the checkbox is checked */
.gopro-checkbox input:checked {
  accent-color: #007bff; /* Change the checkbox color when checked */
}

.gopro-checkbox label {
  cursor: pointer;
  color: #333;
}

.upload-button {
  font-size: 18px;
  padding: 8px 12px;
  width: 100%;
  max-width: 250px;
  background-color: #3300ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button-disabled {
  background-color: #7a7a7a; /* Grey color when no video selected */
  cursor: not-allowed;
}

.upload-button:hover {
  background-color: #0056b3;
}


.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
