/* Header styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #444;
  color: white;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; /* Ensure header is above other elements */
}

.pave-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-left: 10px;
}

.header-logo {
  height: 50px;
  margin-right: 10px;
  background: white;
  border-radius: 50%;
}

.right-section {
  display: flex;
  align-items: center;
  background-color: #008080; /* Teal background */
  padding: 10px;
  width: 20%;
  overflow: hidden; /* Hide overflowing content */
}

/* Topbar buttons */
.topbar-buttons {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
}

.manageButton,
.uploadButton {
  /*background-color: #025b01;*/
  background-color: #025b01;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.manageButton:hover,
.uploadButton:hover {
  background-color: #45a049;
}

/* Filter Container styling */
.filter-container {
  padding: 20px;
  background-color: #b5b5b5;
  width: 100%;
}

.filter-section {
  margin-bottom: 20px;
}

.ratingiri-text {
  padding: 10px;
}

.filter-section p {
  font-weight: bold;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line */
  align-items: center;
  margin-bottom: 10px;
  gap: 10px; /* Space between buttons */
  justify-content: space-evenly; /* Distribute buttons evenly */
}

.button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #ccc;
  text-align: center;
  line-height: 1.5em; /* Adjust to align text vertically */
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em; /* Base font size that scales with the button */
  background-color: #f9f9f9; /* Background color for better visibility */
  padding: 0; /* Remove padding to maintain circular shape */
}

.button.active {
  border-color: #000;
}

.rating-1.active {
  background-color: #FF0000;
}

.rating-2.active {
  background-color: #FFBF00;
}

.rating-3.active {
  background-color: #FFFF00;
}

.rating-4.active {
  background-color: #0000FF;
}

.rating-5.active {
  background-color: #00FF00;
}

.rating-all.active {
  background-color: #7fb9fc;
  color: white;
}

/* Media Query for Responsive Button Layout */
@media screen and (max-width: 1215px) {
  .button-group {
      flex-direction: column; /* Stack buttons vertically */
      align-items: center; /* Center-align the buttons */
  }

  .button {
      width: 40px;
      height: 40px;
      font-size: 0.9em;
  }
}

@media screen and (max-width: 800px) {
  .button {
      width: 35px;
      height: 35px;
      font-size: 0.8em; /* Reduce font size */
  }
}

@media screen and (max-width: 600px) {
  .button {
      width: 30px;
      height: 30px;
      font-size: 0.75em; /* Reduce font size */
  }
}

@media screen and (max-width: 400px) {
  .button {
      width: 25px;
      height: 25px;
      font-size: 0.7em; /* Reduce font size */
  }
}

/* Toggle switch styling for distress-data-switch */
.distress-data-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
  margin-top: 10px; /* Adjust to create space between the heading and the switch */
}

.distress-data-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.distress-data-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.distress-data-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.distress-data-switch input:checked + .distress-data-slider {
  background-color: #00695c; /* Adjust to match your theme */
}

.distress-data-switch input:focus + .distress-data-slider {
  box-shadow: 0 0 1px #00695c;
}

.distress-data-switch input:checked + .distress-data-slider:before {
  transform: translateX(20px);
}

/* Label styling for distress slider */
.distress-data-slider-label {
  vertical-align: middle;
  font-size: 1em;
  color: #333; /* Match with the existing text color */
}

/* Title hiding and centering logo when viewport width is below 1185px */
@media screen and (max-width: 1185px) {
  .pave-title {
      display: none;
  }

  .right-section {
      justify-content: center; /* Center the logo when title is hidden */
  }

  .distress-data-slider-label {
      display: none; /* Hide the distress-data-slider-label */
  }
}
